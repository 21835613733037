import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Articles from '../components/Articles'
import { Helmet } from 'react-helmet'

class BlogIndex extends React.Component<any> {
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout>
        <Helmet>
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
        <Articles data={posts} />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            title
          }
        }
      }
    }
  }
`
