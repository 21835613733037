import React from "react"
import { Link } from "gatsby"

const Articles = (props: any) => {
  return (
    <ul className="articles">
      {props.data.map((item: any, index: number) => {
        const normalizedItem = item.node.frontmatter
        const date = normalizedItem.date
        return (
          <li className="articles--item" key={index}>
            <Link to={item.node.fields.slug}>{normalizedItem.title}</Link>
            <span>{date}</span>
          </li>
        )
      })}
    </ul>
  )
}

export default Articles
